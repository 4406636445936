<template>
  <div>
    <v-card id="card-custom" class="px-2 py-4 rounded-lg">
      <v-card-actions>
        <p class="headline-color px-2">
          Daftar Pendidikan {{ selected.tingkat_pendidikan }}
        </p>
      </v-card-actions>
      <v-divider />
      <v-row class="mx-2">
        <v-col
          cols="12"
          xl="8"
          lg="8"
          md="8"
          sm="12"
          xs="12"
          class="px-0"
        ></v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
          <v-text-field
            v-model="search"
            placeholder="Cari"
            outlined
            dense
            hide-details
            prepend-inner-icon="search"
            class="mx-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        id="table-custom"
        :headers="headers"
        :items="lists"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pagination.totalItem"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems
        }"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small color="primary" @click="handleEdit(item)"
            ><v-icon small>edit</v-icon></v-btn
          >
          <v-btn icon small color="primary" @click="handleDelete(item)"
            ><v-icon small>delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      formLoading: false,
      search: null,
      selected: {
        tingkat_pendidikan: null
      },
      headers: [
        {
          text: "#",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "Kode",
          value: "pendidikan_id",
          sortable: false,
          align: "center"
        },
        {
          text: "Pendidikan",
          value: "pendidikan_name",
          sortable: false,
          align: "center"
        }
      ],
      lists: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["pendidikan_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40]
    };
  },
  watch: {
    options: {
      handler() {
        if (this.selected.tingkat_pendidikan != null) {
          this.getDaftarPendidikanList();
        }
      },
      deep: true
    },
    selected: {
      handler() {
        this.getDaftarPendidikanList();
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getDaftarPendidikanList);
    }
  },
  computed: {
    paginationProperty() {
      return [this.search].join();
    }
  },
  methods: {
    onSelect(item) {
      this.$_.merge(this.selected, item);
      // Delete Later
      const { page, itemsPerPage } = this.options;
      let lists = item.list_pendidikan;
      lists.map((data, index) => {
        data.no = itemsPerPage * (page - 1) + (index + 1);
        return data;
      });
      this.lists = lists;
      this.pagination.page = 1;
      this.pagination.totalItem = 2;
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
